import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { t } from '@lingui/macro'

import { basicShadow } from '../common/styles'
import { Button, IconTextButton } from '../common/buttons'
import Spacing from '../common/Spacing'
import MenuIcon from '../../assets/MenuIcon.svg'
import EvexiaLogoRed from '../../assets/EvexiaLogoRed.svg'
import { toggleSideBar } from '../../redux/reducers/ui'
import EvaluationDialog from '../Patient/EvaluationDialog'

const TopBar = () => {
  // TODO: Check the current path and if we are looking at a patient only then show patient evaluation
  const hasPatient = useRouteMatch('/patients/:id')
  const dispatch = useDispatch()

  const [testOpen, setTestOpen] = React.useState(false)

  return (
    <>
      <TopBarBackground>
        <Buttons>
          <IconTextButton
            label={t`Valikko`}
            onClick={() => dispatch(toggleSideBar())}
            icon={<MenuIcon />}
            strokeColor
          />
          <Spacing amount="xLarge" />
          {hasPatient && <Button label={t`Arvioi potilas`} onClick={() => setTestOpen(true)} />}
        </Buttons>
        <EvexiaLogoRed />
      </TopBarBackground>
      {testOpen && <EvaluationDialog onClose={() => setTestOpen(false)} />}
    </>
  )
}

const TopBarBackground = styled.header`
  background-color: ${props => props.theme.color.white};
  height: 48px;
  z-index: 1000;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;

  ${basicShadow};
`

const Buttons = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

export default TopBar
